h1 {
    font-family: LemonTuesday;
    font-size: 105px;

    @include respond-down(small){
        font-size: 70px;
    }
}

h2 {
	margin: 0.8em 0px;
    font-family: LemonTuesday;
}

h3 {
	margin: 1em 0px;
}

p {
	margin: 1em 0px;
}

a {
    cursor: pointer;
    color: $primary;
    transition: all 0.25s ease-out;
    text-decoration: none;

    &:visited {
        color: $dark;
    }

    &:hover {
        color: $action;
    }
}
