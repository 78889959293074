.contacts-page {
    background: url('/static/images/base/contact.jpg') no-repeat center -100px;
    background-size: 2500px auto;

    @include respond-down(small){
        background-size: 1900px auto;
    }

    .intro {
        margin-top: -200px;
        padding-top: 300px;
        text-align: center;
        padding-bottom: 450px;
        color: #fff;

        @include respond-down(small){
            padding-bottom: 150px;
            margin-top: -200px;
            padding-top: 300px;
        }

        h1 {
            margin-bottom: 20px;
        }
    }

    .contacts {
        color: $default;
        text-align: center;
        padding-bottom: 200px;

        .container {
            position: relative;
        }

        .main {
            font-size: 52px;
            font-weight: 200;
            margin-bottom: 60px;

            @include respond-down(small){
                font-size: 24px;
            }
        }

        p {
            font-size: 35px;
            font-weight: 200;
            margin-bottom: 55px;

            @include respond-down(small){
                font-size: 16px;
                margin-bottom: 30px;
            }
        }

        .big-phone {
            font-size: 77px;
            text-decoration: none;
            margin-bottom: 80px;
            a {
                color: #17B978;
                &:hover {
                    color: #FFC000;
                }
            }

            @include respond-down(small){
                font-size: 35px;
                margin-bottom: 50px;
            }
        }

        .bird-x {
            position: absolute;
            width: 280px;
            left: 10px;
            top: 280px;

            @include respond-down(small){
                width: 210px;
                top: 40px;
            }
        }

        .bird-y {
            position: absolute;
            width: 240px;
            right: 20px;
            top: 400px;

            @include respond-down(small){
                width: 180px;
                top: 100px;
            }
        }
    }
}

