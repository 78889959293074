.cart-box {
    display: none;
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 300;
    background: #F2F2F2;
    box-shadow: -9px 0 48px rgba(0,0,0,0.18);
    width: 500px;
    opacity: 0;
    transition: opacity 0.2s ease-in-out;

    .wrapper {
        max-width: 390px;
        margin: 0 100px;
        display: flex;
        position: absolute;
        height: 100%;
        padding-top: 110px;

        @include respond-down(small) {
            max-width: 226px;
            margin: 0 65px;
        }

        .close-button {
            justify-content: space-between;
            align-items: center;
            height: 150px;
            position: absolute;
            top: 0;

            @include respond-down(small) {
                height: 100px;
            }
        }

    }

    .get-many-wrapper {
        text-align: center;
    }

    .final-box {
        padding: 20px 0;

        .form-control {
            width: 100%;
            margin-bottom: 10px;
        }
        .sum {
            margin-bottom: 15px;
        }

        @include respond-down(small) {
            padding: 10px 0;
        }
    }

    .cart.close {
        &:after {
            background-image: url('/static/svg/cart-close.svg');
        }
    }

    .privacy {
        font-size: 12px;
        color: #999999;
    }
}

.cart-box-background {
    position: fixed;
    left: 0;
    right: 0;
    top:0;
    bottom: 0;
    background: rgba(0,0,0,0.7);
    display: none;
    z-index: 290;
    
    .common-info {
        padding: 40px 40px 0 40px;

        .helper {
            width: 50%;
            text-transform: uppercase;
            font-size: 12px;
            color: $default;
            text-align: left;
            padding: 6px 0;
        }
        .value {
            width: 50%;
            text-align: right;
            font-size: 18px;
            color: #17B978;
        }
    }
    
    .get-many-wrapper {
        text-align: center;
        padding: 0 40px;
    }

    .svirel-cart {
        position: absolute;
        top: 0;
        right: 0px;
        bottom: 0;
        background: #eee;
        margin: 0;
        width: 400px;

        @include respond-down(small){
        	width: 350px;
        }
    }

    .added {
        text-transform: uppercase;
        font-size: 12px;
        color: #494949;
        padding: 40px;
    }

    .svirel-wrapper {
        padding: 0 18px 18px 18px;
    }

    .svirel {
        background: #fff;
        border-radius: 3px;
        position: relative;
        text-align: center;
        margin-bottom: 25px;
        margin-right: 25px;

        display: flex;
        padding-bottom: 10px;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;
        align-items: stretch;

        @include respond-down(small){
            margin-bottom: 10px;
            height: 95px;

            height: auto;
        }

        .title {
            flex: 1 0 45%;
            margin: 14px 7px;
            line-height: 1.1;

            @include respond-up(large){
                font-size: 20px;
            }
        }

        .price {
            flex: 0 0 100px;
            margin: 0px;
            text-align: right;

            &:before {
            	content: '';
            	display: inline-block;
            	height: 100%;
            	width: 0px;
            	vertical-align: middle;
            }

            @include respond-up(large){
                font-size: 20px;
            }
        }

        .counter {
            flex: 1 0 50%;
            padding: 0px;

            .number {
                line-height: 60px;
                display: inline-block;
                overflow: hidden;
                white-space: nowrap;

                @include respond-down(small){
                    line-height: 44px;
                }
                @include respond-down(mobile){
                	font-size: 27px;
                }
            }
        }

        .image {
            flex: 0 0 100px;
            height: auto;
            background: #eee no-repeat center center;
            background-size: cover;
            border-radius: 3px 0 0 0;
        }

        .remove-button {
            position: absolute;
            right: -25px;
            top: 0px;
            opacity: 0.5;
            transition: opacity 0.3s ease-in-out;

            svg path {
                fill: #000;
            }

            &:hover {
                opacity: 1;
            }
        }
    }

    .scroll-box {
        margin-right: 40px;
        position: relative;
        overflow: auto;
        margin-left: 40px;
        margin-bottom: 40px;
        padding: 0;

        &::-webkit-scrollbar {
            position: absolute;
            right: -6px;
            width: 6px;
        }

        /* Track */
        &::-webkit-scrollbar-track {
            background: #FFFFFF;
            border-radius: 3px;
        }

        /* Handle */
        &::-webkit-scrollbar-thumb {
            background: $primary;
            opacity: 0.7;
            border-radius: 3px;
        }

        /* Handle on hover */
        &::-webkit-scrollbar-thumb:hover {
            background: $primary;
        }
    }

}

html.overflow {
    overflow: hidden;

    .cart-box {
        opacity: 1;
    }
}
