.modal {
    opacity: 0;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 400;
    background: rgba(73,73,73,.6);
    transition: opacity 0.3s ease-in-out;
    overflow: auto;
    display: none;

    &.visible {
        opacity: 1;
    }

    .box {
        width: 620px;
        background: #fff;
        margin: 30px auto;
        position: relative;

        .close {
            width: 25px;
            height: 25px;
            position: absolute;
            top: 0;
            right: -45px;
        }
        .body {
            min-height: 300px;
        }
    }

    .form {
        margin: 0 -10px 20px -10px;

        .col {
            flex: 0 0 33.333%;
            padding: 0 10px;
        }

        .form-control, .button {
            width: 100%;
            font-size: 14px;
            padding: 0 15px;
        }
    }
}

.method-modal {
    padding: 35px;
    color: $default;

    .head {
        margin-bottom: 40px;

        .image {
            flex: 0 0 167px;
            height: 245px;
            background: no-repeat center top;
            background-size: cover;
        }

        .info {
            margin-left: 30px;
            height: 245px;
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            .title {
                font-weight: bold;
                font-size: 25px;
                margin: 0;
                line-height: 1.1;
                margin-bottom: 10px;
            }

            .autor {
                font-weight: 200;
                font-size: 19px;
                margin-bottom: 30px;
            }

            .download-button{
                min-width: 200px;
                position: absolute;
                bottom: 0;

            }
        }
    }

    .text {
        padding: 10px 0;
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 20px;
        line-height: 1.6;
    }

    .privacy {
        margin: 0;
        color: #999999;
    }
}

.cart-modal {
    padding: 35px;
    text-align: center;
    color: $default;

    h3 {
        font-size: 19px;
        font-weight: bold;
        font-family: 'Muller';
        margin-bottom: 15px;
    }

    .counter-wrapper {
        display: none;
    }

    .colors {
        justify-content: center;
        margin-bottom: 15px;

        .item {
            padding: 10px;
            color: #B7B7B7;
            font-size: 14px;
            text-align: center;
            font-weight: 200;
            position: relative;
            cursor: pointer;

            &:after {
                content: '';
                position: absolute;
                width: 38px;
                height: 38px;
                border-radius: 19px;
                z-index: 80;
                left: 2px;
                top: 2px;
                border:1px solid transparent;
            }

            .circle {
                border-radius: 12px;
                width: 24px;
                height: 24px;
                border: 1px solid #E4E4E4;
                margin-bottom: 13px;
            }

            .icon {
                width: 24px;
                height: 24px;
                background-repeat: no-repeat;
                background-position: center;
                background-size: contain;
                // background-image: url('../images/image-icons/book-gray.svg');
                margin-bottom: 13px;
            }

            &.current:after {
                border-color: #707070;
            }

            .n {
                margin: 0 -15px;
            }
        }
    }

    .light {
        color: #B6B6B6;
        font-size: 16px;
        font-weight: 200;
        margin-bottom: 10px;
    }

    .counter {
        .number {
            font-size: 69px;
        }
        a svg {
            width: 32px;
            height: 32px;
        }
    }

    .total {
        margin-bottom: 20px;

        .line {
            .name {
                width: 50%;
                text-align: right;
                padding: 5px 15px;
                position: relative;

                &:after {
                    position: absolute;
                    right: -1px;
                    height: 32px;
                    top: -3px;
                    width: 1px;
                    background: #B6B6B6;
                    content: '';
                }
            }
            .n {
                width: 25%;
                text-align: left;
                padding: 5px 15px;
                color: #17B978;
            }
            .remove-button {
                opacity: 0.3;
                cursor: pointer;
                padding: 3px;
                transition: opacity 0.3s ease-in-out;

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .lead {
        font-size: 16px;
        margin-bottom: 20px;
    }

    .privacy {
        margin: 0;
        color: #999999;
    }
}
