.notfound-page {
    text-align: center;
    background: #1793B9 url('/static/images/base/404.jpg') no-repeat center top;
    background-size: 3000px auto;

    @include respond-down(small){
        background-size: 1910px auto;
    }

    h1 {
        position: relative;
        z-index: 10;
        padding-top: 100px;
        color: #fff;
    }

    h2 {
        font-size: 42px;
        font-family: 'Muller';
        color: #fff;
        padding-top: 450px;
        padding-bottom: 30px;
    }

    .intro {
        margin-top: -150px;
        padding-top: 150px;
        margin-bottom: -100px;
        padding-bottom: 100px;
        height: 900px;

        @include respond-down(small){
            margin-top: -100px;
            padding-top: 100px;
            margin-bottom: 50px;
        }

        .svirel {
            position: absolute;
            z-index: 40;
            left: 50%;
            margin-left: -425px;
            top: -1000px;
            padding-top: 1100px;

            @include respond-down(small){
                top: -600px;
                padding-top: 700px;
            }

            .line {
                background: #CBCBCB;
                position: absolute;
                left: 630px;
                z-index: 10;
                width: 2px;
                height: 1500px;
                bottom: 460px;

                @include respond-down(small){
                    left: 430px;
                    width: 1px;
                    height: 600px;
                    bottom: 260px;
                }

            }

            .svirel-image {
                width: 1450px;
                z-index: 20;
                position: relative;

                @include respond-down(small){
                    width: 850px;
                }
            }
        }

        .top {
            width: 100%;
            padding-top: 80%;
            z-index: 50;
            position: relative;

            background: url('/static/images/base/index-top.png') no-repeat center top;
            background-size: 3000px auto;

            @include respond-down(small){
                background-size: 1910px auto;
                padding-top: 60%;
            }
            @include respond-down(mobile){
                padding-top: 80%;
            }

        }
    }
}

