.footer {
    padding-top: 150px;
    margin-top: -200px;
    padding-bottom: 80px;
    color: #fff;
    
    @include respond-down(small) {
        padding-top: 110px;
    }

    .wrapper {
        .logo {
            margin-bottom: 50px;
            
            @include respond-down(small) {
                margin-bottom: 25px;
            }
        }
        
        .lead {
            font-size: 18px;
            margin-bottom: 25px;
            
            @include respond-down(small) {
                font-size: 14px;
                margin-bottom: 12px;
            }
            @include respond-down(mobile){
                font-size: 12px;
            }
        }
        
        .respond {
            font-size: 26px;
            margin-bottom: 30px;
            font-weight: 200;

            @include respond-down(small) {
                font-size: 20px;
                margin-bottom: 12px;
            }
        }

        .big-phone {
            margin-left: -8px;
            margin-right: -8px;
            font-size: 30px;
            margin-bottom: 70px;
            
            @include respond-down(small) {
                margin-bottom: 55px;
            }
        }
        
        .team-wrapper {
            width: 170px;
            height: 56px;
            position: relative;
            
            .team {
                position: relative;
                display: flex;
                height: 56px;
                
                .team-text {
                    border: 4px solid #fff;
                    flex: 1 1 auto;
                    color: #fff;
                    text-transform: uppercase;
                    line-height: 19px;
                    padding: 6px 0px 0px 6px;
                    font-size: 13px;
                    letter-spacing: 0.1em;
                }
                
                svg {
                    width: 30px;
                }
            }
            
            .notki {
                position: absolute;
                right: 20px;
                top: -22px;
                opacity: 0.0;
                transition: opacity 1s ease-in-out, top 2s ease-in-out;
                
                .notka-1 {
                    position: relative;
                    width: 10px;
                    
                }
                .notka-2 {
                    position: relative;
                    left: -4px;
                    width: 17px;
                }
            }
            
            &.animate {
                .notki {
                    top: -25px;
                    opacity: 1;
                }
                .notka {
                    -webkit-animation: updown 3s ease-in-out infinite;
                       -moz-animation: updown 3s ease-in-out infinite;
                         -o-animation: updown 3s ease-in-out infinite;
                            animation: updown 3s ease-in-out infinite;
                }
                .notka-2 {
                    -webkit-animation-delay: 1s;
                       -moz-animation-delay: 1s;
                         -o-animation-delay: 1s;
                            animation-delay: 1s;
                }
            }
            &.stop {
                .notki {
                    opacity: 0;
                }
            }

            @keyframes updown {
                0%   { top: 0px; }
                50%  { top: -7px; }
                100% { top: 0px; }
            }
        }
    }
    
    .footer-columns {
        display: flex;
        
        .logo {
            width: 128px;
        }
        
        .opt {
            margin-top: 30px;
        }
        
        .a {
            line-height: 45px;
            margin: 0 -8px;
            
            a {
                padding: 5px 8px;
                color: #fff;
                &:hover {
                    color: #FFC000;
                }
            }
        }
        
        .col {
            flex: 0 0 50%;
            
            &.left {
                display: flex;
                
                .links {
                    text-align: left;
                    flex: 1 1 35%;
                    padding: 0 10px;
                }
            }
            
            &.right {
                position: relative;
                padding-left: 50px;
                
                &:after {
                    background: #fff;
                    width: 1px;
                    height: 100%;
                    position: absolute;
                    left: 0;
                    top: 0;
                    content: '';
                }
            }
        }
    }
}
