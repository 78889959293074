.material-page {
    background: url('/static/images/base/material.jpg') no-repeat center -100px;
    background-size: 2500px auto;

    @include respond-down(small){
        background-size: 1900px auto;
    }

    .intro {
        margin-top: -200px;
        padding-top: 300px;
        text-align: center;
        padding-bottom: 350px;
        color: #fff;

        @include respond-down(small){
            padding-bottom: 130px;
            margin-top: -100px;
            padding-top: 200px;
        }
    }

    .material {
        position: relative;
        padding-bottom: 180px;
        z-index: 20;

        h3 {
            text-align: center;
            font-weight: bold;
            font-size: 60px;
            margin-bottom: 80px;
            font-family: 'Muller';
            color: $default;

            @include respond-down(small){
                font-size: 40px;
            }
        }

        .materials-items {
            margin: 0 -24px 120px -24px;

            @include respond-down(small){
                margin: 0 -15px 80px -15px;
            }

            .item {
                padding: 0 24px;
                flex: 0 0 25%;

                @include respond-down(small){
                    padding: 0 15px;
                }

                .shadow-box {
                    padding: 48px;

                    @include respond-down(small){
                        padding: 30px;
                    }

                    &.method {
                        padding-top: 80px;

                        @include respond-down(small){
                            padding-top: 50px;
                        }
                    }
                }

                .image {
                    width: 100%;
                    height: 450px;
                    background: #eee no-repeat center center;
                    background-size: cover;
                    display: block;
                    margin-bottom: 70px;

                    @include respond-down(small){
                        height: 268px;
                        margin-bottom: 40px;
                    }
                }

                .autor {
                    display: block;
                    font-size: 20px;
                    font-weight: 200;
                    margin-bottom: 5px;

                    @include respond-down(small){
                        font-size: 12px;
                        margin-bottom: 2px;
                    }
                }

                .title {
                    display: block;
                    font-size: 25px;
                    font-weight: bold;
                    color: $default;
                    margin-bottom: 25px;
                    height: 95px;
                    overflow: hidden;

                    @include respond-down(small){
                        font-size: 16px;
                        margin-bottom: 15px;
                        height: 60px;
                    }
                }
            }
        }
    }
}
