.menu {
    position: relative;
    z-index: 80;

    ul {
        margin: 0;
        padding: 0;
        list-style: none;
    }

    .flex {
        justify-content: space-between;
        align-items: center;
        height: 150px;

        @include respond-down(small) {
            height: 100px;
        }
    }

    .logo {
        flex: 0 0 260px  ;
        display: block;

        @include respond-down(small){
            flex: 0 0 155px;
        }

    }

    .button {
        margin: 0 25px;

        @include respond-down(small) {
            margin: 0 10px;
        }
        @include respond-down(mobile){
            margin: 0 5px;
            padding: 0 12px;
        }

        &.current {
            background: rgba(255,255,255,0.4);
            box-shadow: inset 0 0 0 2px #fff;
        }
    }

    .cart-space {
        display: none;
        width: 275px;

        @include respond-down(small) {
            width: 200px;
        }
    }
}

.menu-button-hide {
    .cart-fix > .container {
        .button.cart {
            margin-top: -100px;
        }
    }
}

.menu-sticky {
    .cart-fix > .container {
        .cart-space {
            display: inline-block;
        }

        .button.cart {
            box-shadow: 0 10px 44px rgba(0,0,0,0.18);
            position: fixed;
            left: 50%;
            margin-left: 1780px/2 - 275px;
            margin-top: -100px;
            transition:
                margin-top 0.3s ease-out,
                background 0.3s ease-out,
                color 0.3s ease-out,
                box-shadow 0.3s ease-out;

            @include respond-down(small) {
                margin-left: 1060px/2 - 200px;
            }
            @include respond-down(mobile){
                right: 20px;
                left: auto;
            }
        }
    }

    .menu.show-it {
        .button.cart {
            margin-top: 0px;
        }
    }
}

.menu .button.cart, .cart-box .button.cart {
    flex: 0 0 auto  ;
    background: #FFC000;
    position: relative;
    margin: 0;
 	width: 275px;

    @include respond-down(small) {
    	width: 200px;
    }

    .icon-cart {
        position: absolute;
        right: 15px;
        top: 50%;
        width: 54px;
        margin-top: -27px;
        height: 54px;

        @include respond-down(small) {
            width: 32px;
            margin-top: -16px;
            height: 32px;
            right: 11px;
        }
    }

    &.added {
        animation: item-added 0.7s ease-out both;
    }

    .n {
    	// display: none;
    }
}

@keyframes item-added {
    0%  { transform: translate(0, 0); }
    20% { transform: translate(-15px, 0); }
    40% { transform: translate(15px, 0); }
    60% { transform: translate(-15px, 0); }
    80% { transform: translate(15px, 0); }
    100% { transform: translate(0, 0); }
}

