.article-items {
    align-items: stretch;

    .item {
        flex: 1 1 33.33%  ;
        padding: 0 18px;

        .shadow-box {
            display: block;
            height: 100%;
            border-radius: 0;
            text-decoration: none;
            text-align: left;
            padding-bottom: 40px;

            .image {
                height: 317px;
                display: block;

                @include respond-down(small){
                    height: 190px;
                }
            }

            .title {
                overflow: hidden;
                display: block;
                height: 150px;
                margin-bottom: 20px;

                display: block;
                font-size: 31px;
                font-weight: bold;
                color: $default;
                padding: 30px 15px 30px 45px;

                @include respond-down(small){
                    font-size: 20px;
                    padding: 20px 10px 15px 20px;
                    max-width: 315px;
                    height: 105px;
                    margin-bottom: 15px;
                }
            }

            .description {
                display: block;
                font-size: 21px;
                font-weight: 200;
                color: $text;
                padding: 0 35px 0 45px;
                letter-spacing: 0.05em;
                height: 132px;
                overflow: hidden;

                @include respond-down(small){
                    font-size: 14px;
                    padding: 0 10px 0 20px;
                    height: 74px;
                }
            }
        }
    }
}

.articles {
    h2 {
        font-size: 197px;

        @include respond-down(small){
            font-size: 120px;
        }
    }

    .wrapper {
        overflow: hidden;
    }

    .sectors {
        width: 500%;
        left: 0;
        overflow: hidden;
        position: relative;
        transition: all 0.3s ease-in-out;

        .sector {
            width: 20%;
            float: left;
        }
    }

    .selector {
        margin-bottom: 50px;
    }

    .article-items {
        margin-bottom: 50px;

        @include respond-down(small){
            margin-bottom: 15px;
        }

        .item {
            margin-bottom: 70px;

            @include respond-down(small){
                margin-bottom: 45px;
            }

            .title {
                overflow: hidden;
                display: block;
            }

            .description {
            	height: auto;
            }
        }
    }
}

.decor {
    display: none;
    width: 150px;
    position: absolute;
    top: 20px;

    &.bird-x {
        left: -200px;
    }
    &.bird-y {
        right: -200px;
    }
}
