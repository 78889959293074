/* font-family: "LemonTuesday"; */
@font-face {
    font-family: "LemonTuesday";
    src: url("../fonts/LemonTuesday/LemonTuesday.otf");
    src: url("../fonts/LemonTuesday/LemonTuesday.otf?#iefix")format("opentype"),
    url("../fonts/LemonTuesday/LemonTuesday.woff") format("woff"),
    url("../fonts/LemonTuesday/LemonTuesday.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
