.history-page {
    background: url('/static/images/base/history.jpg') no-repeat center top;
    background-size: 2800px auto;

    @include respond-down(small){
        background-size: 1900px auto;
    }

    .intro {
        margin-top: -200px;
        padding-top: 300px;
        text-align: center;
        padding-bottom: 300px;
        color: #fff;

        @include respond-down(small){
            padding-bottom: 280px;
            margin-top: -100px;
            padding-top: 200px;
        }
    }

    .slick-arrow {
    	border: none;
    	width: 48px;
    	height: 48px;
    	position: absolute;
    	top: 50%;
    	margin-top: -24px;
    	z-index: 300;
    	// text-indent: 9999px;
    	cursor: pointer;
    	opacity: 0.7;
    	transition: opacity 0.3s ease-in-out;
    	background: rgb(22,185,120);
    	border-radius: 24px;

    	.svg-icon {
    		height: 100%;
    		width: 100%;
    	}


    	&:hover {
    		opacity: 1;
    	}

    	&.slick-next {
    		right: 5px;

    	}
    	&.slick-prev {
    		left: 5px;
    	}
    }

    .videos {
    	position: relative;
        width: 721px;
        margin-bottom: 30px;

        @include respond-down(small){
            width: 512px;
        }

        .video-slider {
            overflow: hidden;
            position: relative;
        }
    }

    .video-box {
        margin-bottom: 30px;
        margin-top: -370px;
        padding-top: 300px;
        z-index: 60;
        position: relative;
        overflow: hidden;

        .container.flex {
        	align-items: flex-start;
        }

        .video {
            padding: 0 20px;
            float: left;
            width: 721px;
            height: 402px;
            display: block;
            position: relative;
            background: #eee no-repeat center center;
            background-size: cover;

            @include respond-down(small){
                margin-left: 0;
                width: 512px;
                height: 268px;
            }
        }

        .play {
            padding: 0;
            width: 96px;
            height: 96px;
            border-radius: 48px;
            line-height: 130px;
            text-align: center;
            position: absolute;
            left: 50%;
            top: 50%;
            margin-left: -48px;
            margin-top: -48px;


            svg {
                width: 30px;
                margin-left: 9px;
            }
        }

        .tree,
        .icon-tree {
        	height: 600px;
            width: 880px;
            position: absolute;
            right: -80px;
            top: 50px;
            z-index: 1;

            @include respond-down(small){
                width: 780px;
            }
        }

        .text {
            padding: 300px 30px 100px 30px;
            width: 675px;
            font-size: 21px;
            font-weight: 200;
            color: $default;
            line-height: 1.6em;

            @include respond-down(small){
                padding-top: 150px;
                font-size: 16px;
            }

            p {
                margin-bottom: 18px;
            }
        }
    }

    .articles {
        background: #17B978 url('/static/images/base/hystory-articles.jpg') no-repeat center top;
        background-size: 2500px auto;

        @include respond-down(small){
            background: #17B978 url('/static/images/base/hystory-articles.jpg') no-repeat center top;
            background-size: 1900px auto;
        }

        z-index: 50;
        text-align: center;
        color: #fff;
        padding-top: 200px;
        margin-top: -300px;
        padding-bottom: 300px;

        @include respond-down(small){
            padding-bottom: 220px;
        }
    }
}

.video-frame {
    display: block;
    margin: 0;
}
