/* font-family: "MullerRegular"; */
@font-face {
    font-family: "Muller";
    src: url("../fonts/MullerRegular/MullerRegular.otf");
    src: url("../fonts/MullerRegular/MullerRegular.otf?#iefix")format("opentype"),
    url("../fonts/MullerRegular/MullerRegular.woff") format("woff"),
    url("../fonts/MullerRegular/MullerRegular.ttf") format("truetype");
    font-style: normal;
    font-weight: normal;
}
/* font-family: "MullerBold"; */
@font-face {
    font-family: "Muller";
    src: url("../fonts/MullerBold/MullerBold.otf");
    src: url("../fonts/MullerBold/MullerBold.otf?#iefix")format("opentype"),
    url("../fonts/MullerBold/MullerBold.woff") format("woff"),
    url("../fonts/MullerBold/MullerBold.ttf") format("truetype");
    font-style: normal;
    font-weight: bold;
}
/* font-family: "MullerLight"; */
@font-face {
    font-family: "Muller";
    src: url("../fonts/MullerLight/MullerLight.otf");
    src: url("../fonts/MullerLight/MullerLight.otf?#iefix")format("opentype"),
    url("../fonts/MullerLight/MullerLight.woff") format("woff"),
    url("../fonts/MullerLight/MullerLight.ttf") format("truetype");
    font-style: normal;
    font-weight: 200;
}
