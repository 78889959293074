.index-page {
    text-align: center;
    background: url('/static/images/base/index-bottom.jpg') no-repeat center top;
    background-size: 3000px auto;

    @include respond-down(small){
        background-size: 1910px auto;
    }

    h1 {
        font-size: 197px;
        color: #fff;
        padding-top: 420px;

        @include respond-down(small){
            font-size: 120px;
            padding-top: 232px;
        }
    }
    h2 {
        font-size: 197px;
        color: #fff;

        @include respond-down(small){
            font-size: 120px;
        }
    }
    .lead {
        color: #fff;
        font-size: 37px;
        margin-bottom: 100px;

        @include respond-down(small){
            font-size: 24px;
            margin-bottom: 55px;
        }
    }

    .intro {
        margin-top: -150px;
        padding-top: 150px;
        margin-bottom: -800px;
        padding-bottom: 800px;

        @include respond-down(small){
            margin-top: -100px;
            padding-top: 100px;
        }

        .svirel {
            position: absolute;
            z-index: 40;
            right: -50px;
            top: -1000px;
            padding-top: 1100px;

            @include respond-down(small){
                top: -600px;
                padding-top: 700px;
            }

            .line {
                background: #CBCBCB;
                position: absolute;
                left: 630px;
                z-index: 10;
                width: 2px;
                height: 1500px;
                bottom: 460px;

                @include respond-down(small){
                    left: 430px;
                    width: 1px;
                    height: 600px;
                    bottom: 260px;
                }

            }

            .svirel-image {
                width: 1450px;
                z-index: 20;
                position: relative;

                @include respond-down(small){
                    width: 850px;
                }
            }
        }

        .top {
            width: 100%;
            padding-top: 80%;
            z-index: 50;
            position: relative;

            background: url('/static/images/base/index-top.png') no-repeat center top;
            background-size: 3000px auto;

            @include respond-down(small){
                background-size: 1910px auto;
                padding-top: 60%;
            }
            @include respond-down(mobile){
                padding-top: 80%;
            }

        }
    }

    .items-wrapper {
        justify-content: center;
    }

    .svirel {
        position: relative;
        z-index: 40;

        .item {
            .image {
                border-radius: 4px 4px 0 0;
                height: 265px;
                margin-bottom: 30px;

                @include respond-down(small){
                    height: 152px;
                    margin-bottom: 8px;
                }
            }
            .shadow-box {
                padding-bottom: 48px;

                @include respond-down(small){
                    padding-bottom: 32px;
                }

                .button {
                    margin-left: 48px;
                    margin-right: 48px;

                    @include respond-down(small){
                        margin-left: 32px;
                        margin-right: 32px;
                    }
                }
            }
        }
    }

    .optom {
        color: #fff;
        padding-top: 400px;
        margin-top: -305px;
        font-size: 21px;
        z-index: 30;

        @include respond-down(small){
            padding-top: 330px;
        }

        svg {
            top: -300px;

            @include respond-down(small){
                top: -250px;
            }
        }

        .wrapper {
            background: #0074E4;
            padding-bottom: 270px;

            h2 {
                font-size: 155px;
                margin-bottom: 37px;

                @include respond-down(small){
                    font-size: 120px;
                    margin-bottom: 15px;
                }
            }

            .lead {
                margin-bottom: 50px;

                @include respond-down(small){
                    margin-bottom: 18px;
                }
            }

            .helper {
                margin-bottom: 40px;

                @include respond-down(small){
                    margin-bottom: 22px;
                    font-size: 16px;
                }
            }

            .big-phone {
                margin-bottom: 50px;
            }
        }
    }

    .articles {
        padding-top: 175px;
        margin-top: -255px;
        z-index: 40;

        background: url('/static/images/base/index-articles.jpg') no-repeat center top;
        background-size: 3000px auto;

        @include respond-down(small){
            padding-top: 0px;
            background-size: 2000px auto;
        }

        .wrapper {
            padding-bottom: 200px;

            h2 {
                padding-top: 30px;
                margin-bottom: 90px;
            }

            .article-items {
                margin-left: -18px;
                margin-right: -18px;
                margin-bottom: 95px;

                @include respond-down(small){
                    margin-bottom: 45px;
                }
            }
        }
    }
}

.opt-request {
    justify-content: space-around;
}

