.learn-page {
    background: url('/static/images/base/learn.jpg') no-repeat center -100px;
    background-size: 2500px auto;

    @include respond-down(small){
        background: url('/static/images/base/learn.jpg') no-repeat center -150px;
        background-size: 1900px auto;
    }

    .intro {
        margin-top: -200px;
        padding-top: 300px;
        text-align: center;
        padding-bottom: 300px;
        color: #fff;

        @include respond-down(small){
            margin-top: -100px;
            padding-top: 200px;
            padding-bottom: 150px;
        }

        .lead {
            margin-bottom: 50px;
        }
    }

    .learn {
        overflow: hidden;
        position: relative;
        padding-bottom: 100px;
        z-index: 20;
        color: $default;

        &.verde {
            background: #16b978;
            padding-bottom: 250px;
        }

        h3 {
            text-align: center;
            font-weight: bold;
            font-size: 60px;
            margin-bottom: 80px;
            font-family: 'Muller';

            @include respond-down(small){
                font-size: 40px;
                margin-bottom: 65px;
            }

        }
        strong {
            font-weight: 400;
        }

        .bird {
            position: absolute;
            top: 420px;
            right: -300px;
            width: 260px;

            @include respond-down(small){
                width: 230px;
                top: 120px;
            }
        }
        .leaf {
            position: absolute;
            top: 1100px;
            right: -200px;
            width: 150px;

            @include respond-down(small){
                width: 100px;
                top: 950px;
            }
        }
        .tree-left {
            position: absolute;
            left: -280px;
            top: 400px;
            width: 765px;

            @include respond-down(small){
                left: -380px;
                width: 665px;
            	top: 600px;
            }
        }
        .svirelka {
            width: 100%;
            margin-bottom: 150px;

            @include respond-down(small){
                margin-bottom: 80px;
            }
        }

        .page {
            position: relative;
            width: 870px;
            margin: 0 auto;
            font-size: 27px;
            font-weight: 200;

            @include respond-down(small){
                width: 548px;
                font-size: 16px;
            }

            p {
                margin-bottom: 35px;
            }
        }

        .table {
            margin-bottom: 80px;

            .head {
                .svg-icon {
                    margin: -17px 20px -17px 0;

                    @include respond-down(small){
                        width: 35px;
                        margin: -22px 10px -22px 0;
                    }
                }
                .notka .svg-icon {
                    margin: -10px 20px -10px 0;
                }
            }
            .row {
                border-bottom: 1px solid $default;
            }
            .col {
                width: 50%;
                padding: 35px 0;

                @include respond-down(small){
                    padding: 22px 0;
                }
            }
        }
    }

    .videos {
        margin-top: -100px;
        padding-top: 350px;
        padding-bottom: 220px;
        text-align: center;
        color: #fff;

        @include respond-down(small){
            margin-top: -130px;
            padding-top: 280px;
        }

        svg {
            top: -540px;

            @include respond-down(small){
                top: -390px;
            }
        }

        .lead {
            font-size: 35px;
            font-weight: 200;
            margin-bottom: 60px;

            @include respond-down(small){
                margin-bottom: 40px;
            }

            .small {
                // O_o

                @include respond-down(small){
                    font-size: 16px;
                }
            }

            @include respond-down(small){
                font-size: 24px;
            }
        }

        &:after {
            content: '';
            position: absolute;
            z-index: 5;
            width: 100%;
            bottom: 0;
            left: 0;
            top: 300px;
            right: 0;
            height: 800px;
            background: #17B978;
        }
    }

    &.sitemap {
        h3 {
            font-size: 20px;
            text-align: left;
            color: #fff;
            margin-bottom: 10px;
        }

        .url {
            font-size: 16px;
            margin-bottom: 8px;
            font-weight: 400;
            display: block;
            margin-left: 40px;

            a {
                padding: 5px 0;
                color: #fff;
                &:hover {
                    color: #FFC000;
                }
            }

        }
    }
}
