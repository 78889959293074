.svirel {
    .price {
        color: $primary;
        font-size: 30px;
        margin-bottom: 10px;

        @include respond-down(small){
            font-size: 14px;
        }
    }
    .title {
        color: $default;
        font-size: 21px;
        margin-bottom: 30px;

        @include respond-down(small){
            font-size: 16px;
            margin-bottom: 0px;
            margin-bottom: 15px;
        }
    }

    .counter {
        justify-content: center;
        align-items: center;
        color: $default;
        font-size: 51px;
        padding: 10px 0 20px 0;

        @include respond-down(small){
            font-size: 34px;
            padding: 3px 0 10px 0;
        }

        a {
            display: flex;
            align-items: center;
            padding: 0 15px;

            .this {
                transition: all 0.3s ease-out;
            }

            &.add:hover svg .this {
                fill: #17b978;
            }
            &.remove:hover svg .this {
                fill: #1793b9;
            }

            svg {
                width: 25px;
                height: 25px;
                @include respond-down(small){
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
}
